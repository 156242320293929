import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Numbers, abbreviations and equations can pose a challenge for screen reader users. Using
the `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{` CSS property, you can determine how text should be read. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGaplOaY5//xAAaEAEAAwADAAAAAAAAAAAAAAACAAEDERIi/9oACAEBAAEFAsSVDn0mnhrOuGrLStL/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIRJR/9oACAEBAAY/An0PunhRCs2hzXpbP//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExQZH/2gAIAQEAAT8hY+BL4saAZsHOkruAMeygy7FJecjruz//2gAMAwEAAgADAAAAEEjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EEAMn//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxAdn//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVFxgZH/2gAIAQEAAT8Q6eAANKrVwBwtA7cPLkGiaFnIPUKapluLzf5MkQFJRx6wj2C20UfJ/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Woman Speaking into a Megaphone",
          "title": "Woman Speaking into a Megaphone",
          "src": "/static/2cf1b1bd6dc5e1242769dab7de8c837c/e5166/pexels-edmond-dantes-megaphone.jpg",
          "srcSet": ["/static/2cf1b1bd6dc5e1242769dab7de8c837c/f93b5/pexels-edmond-dantes-megaphone.jpg 300w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/b4294/pexels-edmond-dantes-megaphone.jpg 600w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/e5166/pexels-edmond-dantes-megaphone.jpg 1200w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/b17f8/pexels-edmond-dantes-megaphone.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Edmond Dantès / pexels.com`}</em></p>
    <h2>{`To spell, or not to spell, that is the question`}</h2>
    <p>{`Some days ago, I consulted a client in the banking industry. They want to optimize their web application for
screen reader users. One of their questions was: How can we make the screen reader read numbers one at a time?
For example, a bank account number with 12 digits or more?`}</p>
    <p>{`In many cases, screen readers will announce the whole number instead of the single digits. For example, 9100 will
be read as “nine thousand one hundred” instead of “nine one zero zero”. Similar problems can arise with abbreviations
or equations. It all comes down to the
`}<a parentName="p" {...{
        "href": "https://www.deque.com/blog/dont-screen-readers-read-whats-screen-part-1-punctuation-typographic-symbols/"
      }}>{`screen reader's inherent heuristics`}</a>{`
that determine how to read specific letters and punctuation.`}</p>
    <p>{`Well, wouldn't it be great if we could just tell the screen reader how to read certain content? The answer is:
Yes, we can! But browser support is really bad.`}</p>
    <h2>{`The CSS speak-as property`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-speech-1/"
      }}>{`CSS Speech Module`}</a>{` defines the `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{` property.
It determines in what manner text gets rendered aurally, based upon a predefined list of possibilities:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`normal`}</strong>{`: Uses the browser's language-dependent pronunciation rules (default).`}</li>
      <li parentName="ul"><strong parentName="li">{`spell-out`}</strong>{`: Instructs the browser to spell the text one letter at a time (useful for acronyms and abbreviations).`}</li>
      <li parentName="ul"><strong parentName="li">{`digits`}</strong>{`: Speak numbers one digit at a time, like 9100 would be read “nine one zero zero”.`}</li>
      <li parentName="ul"><strong parentName="li">{`literal-punctuation`}</strong>{`: Spells out punctations (like semicolons, braces, etc.) rather than treating them like pauses.`}</li>
      <li parentName="ul"><strong parentName="li">{`no-punctuation`}</strong>{`: Entirely skips punctuation.`}</li>
    </ul>
    <p>{`Using this property, we could define a class for bank account numbers like this:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.bank-account-number {
    speak-as: digits;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Browser and Screen Reader Support`}</h2>
    <p>{`This all sounds great in theory. Unfortunately, it won't work with most browsers and screen readers. I put together
`}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/OJOMmzm"
      }}>{`a demo on Codepen`}</a>{` and tested the following scenarios:`}</p>
    <ol>
      <li parentName="ol">{`NVDA 2021.3.1, Google Chrome 98.0.4758.82, Windows 10`}</li>
      <li parentName="ol">{`TalkBack, Firefox 97.1.0, Android 12`}</li>
      <li parentName="ol">{`VoiceOver, Safari 15, iOS 15.1`}</li>
    </ol>
    <p>{`Out of the three, only VoiceOver does a great job and reads the content according to the `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{`
property's value. NVDA and TalkBack completely ignore it.`}</p>
    <p>{`Considering the fact that the CSS Speech Module was written over 10 years ago, I'm very disappointed by the current
situation. I hope that more browsers and screen readers will support the feature in the future!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      